import { BranchEndpointService } from './../../common/_services/branch-endpoint.service';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';
import { BranchQueue, Token } from '../../common';
import { getDurationInSeconds } from '../../common/_utils/time.util';
import { Subscription, timer } from 'rxjs';

type LongestWaitingToken = Token;

@Component({
  selector: 'app-view-board-queue-list',
  templateUrl: './queue-list.component.html',
  styleUrls: ['./queue-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ViewBoardQueueListComponent implements OnChanges, OnDestroy {
  @Input() queues: BranchQueue[] = [];
  @Input() selectedQueue: BranchQueue;
  @Output() onQueueClick = new EventEmitter<BranchQueue>();

  private waitTimeSub: Subscription = null;

  public longestWaitingToken: LongestWaitingToken;

  constructor(public branchService: BranchEndpointService) {}

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
    if (changes.queues) {
      this.longestWaitingToken = this.queues.reduce<Token>((acc: LongestWaitingToken, val) => {
        if (!acc) return val?.nextPendingToken;
        if (!val?.nextPendingToken?.tokenNumber) return acc;

        const valMinutes = getDurationInSeconds(val?.nextPendingToken.createdAt);
        const accMinutes = getDurationInSeconds(acc.createdAt);

        return valMinutes > accMinutes ? val?.nextPendingToken : acc;
      }, null);

      this.startWaitingTimeInterval();
    }
  }

  ngOnDestroy(): void {
    this.waitTimeSub?.unsubscribe();
  }

  setLongestWaitingTokenTimedValues() {
    if (!this?.longestWaitingToken?.tokenNumber) return;
    const { happy, neutral } = this.branchService?.details || {};
    this.longestWaitingToken.updateWaitTime(happy, neutral);
  }

  handleQueueClick(queue: BranchQueue) {
    this.onQueueClick.emit(queue);
  }

  handleLongestWaitingTokenClick(branchQueueId: number) {
    console.log('handleLongestWaitingTokenClick', branchQueueId, this.queues);
    if (!this.longestWaitingToken) return;

    const queue = this.queues.find((it) => it.id == branchQueueId);
    if (!queue) return;

    this.onQueueClick.emit(queue);
  }

  startWaitingTimeInterval() {
    const now = Date.now();
    const delay = (60 - ((now / 1000) % 60)) * 1000;
    const interval = 1000 * 60;

    this.setLongestWaitingTokenTimedValues();

    this.waitTimeSub = timer(delay, interval).subscribe(() => {
      this.setLongestWaitingTokenTimedValues();
    });
  }
}
