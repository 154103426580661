import { Token } from './token';
import { Counter } from './counter';
import { Mood } from './mood';

export class BranchQueue {
  public id;
  public name;
  public currentToken: Token;
  public nextPendingToken: Token;
  public counters: Array<Counter>;
  public value;
  public label;
  public animationFlag;
  public hover;
  public totalPeopleInQueue;
  public userAbandons;
  public mood: Mood;
  public sound;

  constructor() {
    this.animationFlag = false;
    this.userAbandons = 0;
    this.mood = new Mood();
  }

  setBranchQueueData(data, displayName = '') {
    this.id = data.id;
    this.name = displayName || data.attributes.name;
    this.totalPeopleInQueue = data.attributes.peopleInQueue;
    this.userAbandons = data.attributes.userAbandons;
    this.value = this.id;
    this.label = this.name;
    this.currentToken = new Token();
    this.currentToken.setTokenSerializedData(data.attributes.currentTokenNumber);
    this.sound = data.attributes.sound;
    this.counters = new Array<Counter>();
    this.nextPendingToken = new Token();

    if (data.attributes.nextPendingToken) {
      this.nextPendingToken.setTokenData({
        id: data.attributes.nextPendingToken.id,
        attributes: {
          tokenNumber: data.attributes.nextPendingToken?.token_number,
          status: 'pending',
          originType: data.attributes.nextPendingToken?.origin_type,
          branchQueueId: data.attributes.nextPendingToken?.branch_queue_id,
          createdAt: data.attributes.nextPendingToken?.created_at,
          branchQueueName: this.name,
          tokenTags: data.attributes.nextPendingToken?.token_tags || [],
        },
      });
    }

    return [this.id, this.name, this.currentToken];
  }
}
