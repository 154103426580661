import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NewTokenData, PusherService } from 'app/common/_services/pusher.service';
import { timer } from 'rxjs';

import {
  MainPageEndpointsService,
  ViewBoardEndpointsService,
  DataService,
  BranchQueue,
  EmployeeConsole,
  Token,
  GlobalVariables,
  BranchEndpointService,
} from '../common/index';

declare var swal: any;

@Component({
  selector: 'view-board',
  templateUrl: './view-board.component.html',
  styleUrls: ['./view-board.component.scss'],
  providers: [ViewBoardEndpointsService],
  encapsulation: ViewEncapsulation.None,
})
export class ViewBoardComponent implements OnInit, OnDestroy {
  queues: BranchQueue[];
  selectedQueueTokens: Token[];
  queueCounters: EmployeeConsole[] = [];
  selectedCounters: number[] = []; // These consoles are cached and visible when a user adds them one
  message: string;
  branchData;
  selectedQueue: BranchQueue;
  queuesLoaded = false;
  consolesLoaded = false;
  waitTimesSub; // Subscriber for timer
  totalAbandons = 0;
  tokenSliding = false;
  showCountersModal = false;
  requestLocked = false;

  constructor(
    private router: Router,
    private mainPageEndpoints: MainPageEndpointsService,
    private viewBoardEndpoints: ViewBoardEndpointsService,
    private branchEndpoints: BranchEndpointService,
    private dataService: DataService,
    private pusherService: PusherService
  ) {}

  ngOnInit() {
    this.loadBranch();
    const branchId = +window.localStorage.getItem('branchData');
    this.pusherService.connectTokenBroadcast(branchId, this.handleNewTokenData);
  }

  ngOnDestroy() {
    this.waitTimesSub?.unsubscribe(() => (this.waitTimesSub = null));
    this.queueCounters.forEach((it) => it.cleanServingTimer());
  }

  loadBranch() {
    this.viewBoardEndpoints.getBranchData().subscribe(
      (data: any) => {
        this.branchData = data.data;
        this.loadBranchQueues();
      },
      (error) => this.handleApiError(error)
    );
  }

  loadBranchQueues(): void {
    this.mainPageEndpoints.getQueuesData().subscribe(
      (data: any) => {
        this.queues = [];

        let queueData = data.data;

        queueData.forEach((it) => {
          const queueInstance = new BranchQueue();
          queueInstance.setBranchQueueData(it);
          queueInstance.mood.setMoodData(this.branchData);
          this.queues.push(queueInstance);
        });

        this.selectedQueue = this.queues[this.queues.length - 1];

        this.queuesLoaded = true;
        this.loadTokens();
      },
      (error) => this.handleApiError(error)
    );
  }

  loadTokens(): void {
    this.viewBoardEndpoints.getPendingTokens(this.selectedQueue.id).subscribe(
      (data: any) => {
        this.selectedQueueTokens = new Array<Token>();

        let tokenData = data.data;
        for (var i = 0; i < tokenData.length; i++) {
          let tokenInstance = new Token();
          tokenInstance.setTokenData(tokenData[i]);
          tokenInstance.setMood(this.selectedQueue.mood);
          this.selectedQueueTokens.push(tokenInstance);
        }
        tokenData = null;
        this.waitingTimesForToken();

        this.loadConsolesData();
      },
      (error) => this.handleApiError(error)
    );
  }

  loadConsolesData(consoleIds: number[] = []) {
    this.viewBoardEndpoints.getConsoles(this.selectedQueue.id, consoleIds).subscribe(
      ({ data }) => {
        data.forEach((it) => {
          const counter = new EmployeeConsole();
          counter.setConsoleData(it);

          if (counter.currentlyServing.status === 'serving') {
            counter.startServingTimer();
          }

          this.queueCounters.push(counter);
        });

        this.setCountersFromCache();
        this.countAbandonments();

        this.consolesLoaded = true;
      },
      (error) => this.handleApiError(error)
    );
  }

  getCounterById(id: number) {
    return this.queueCounters.find((it) => it.id === id);
  }

  handleNewTokenData = (data: NewTokenData) => {
    if (!data?.data?.attributes) return;
    const { attributes, id } = data.data;

    // update total people in queue
    const queueIndex = this.queues?.findIndex((x) => x.id == attributes.branchQueueId);

    if (queueIndex > -1) {
      this.queues = this.queues.map((it, index) => {
        if (index === queueIndex) {
          it.totalPeopleInQueue = attributes.totalPeopleInQueue;

          it.nextPendingToken.setTokenData({
            id,
            attributes: {
              tokenNumber: attributes.nextPendingTokenNumber,
              createdAt: attributes.nextPendingTokenCreatedAt,
              status: 'pending',
              branchQueueId: attributes.nextPendingTokenBranchQueueId,
              branchQueueName: it.name,
              originType: attributes.nextPendingTokenOriginType,
              tokenTags: attributes.nextPendingTokenTags,
            },
          });
        }
        return it;
      });
    }

    if (this.selectedQueue.id != attributes.branchQueueId) return;

    let tokenInstance = new Token();
    tokenInstance.setTokenData(data.data);

    let index: number;

    switch (attributes.status) {
      case 'serving':
      case 'no_show':
      case 'served':
      case 'deleted':
        index = this.selectedQueueTokens.findIndex((x) => x.id == tokenInstance.id);
        index > -1 && this.selectedQueueTokens.splice(index, 1);

        const counter = this.queueCounters.find((it) => `${it?.id}` === `${attributes.counterId}`);

        if (counter) {
          counter.setCurrentlyServing({
            id: data.data.id,
            tokenNumber: attributes.tokenNumber,
            status: attributes.status,
            originType: attributes.originType,
            branchQueueId: attributes.branchQueueId,
            startTime: attributes.startTime,
            branchQueueName: attributes.branchQueueName,
          });
        }
        break;
      case 'pending':
        tokenInstance.setMood(this.selectedQueue.mood);
        this.selectedQueueTokens.push(tokenInstance);
        navigator?.vibrate?.([300, 300]);
        break;
      default:
        break;
    }
  };

  countAbandonments(): void {
    let abandons = this.selectedQueue.userAbandons;

    if (this.selectedCounters?.length > 0) {
      abandons += this.queueCounters
        .filter((it) => this.selectedCounters.includes(it.id))
        .reduce((acc, it) => acc + it.abandonments, 0);
    }
    this.totalAbandons = abandons;
  }

  getTotalAbandons() {
    this.viewBoardEndpoints.getBranchQueueData(this.selectedQueue.id).subscribe(
      (data: any) => {
        if (data.data) {
          this.selectedQueue.userAbandons = data.data.attributes.userAbandons;
        }
        this.countAbandonments();
      },
      (error) => this.handleApiError(error)
    );
  }

  countPeopleServed() {
    return this.queueCounters?.reduce((acc, it) => acc + it.peopleServed, 0) || 0;
  }

  addCounterClick() {
    this.showCountersModal = true;
  }

  handleCounterClick(counter: EmployeeConsole): void {
    this.showCountersModal = false;
    this.selectedCounters.push(counter.id);
    this.updateCounterCache();
    this.countAbandonments();
  }

  removeCounter(counter: EmployeeConsole): void {
    if (counter.isServing()) {
      swal({
        text: 'Current Token needs to be served before deleting the counter',
        type: 'warning',
        allowOutsideClick: false,
        showCancelButton: false,
        allowEscapeKey: false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Continue',
      });
    } else {
      this.selectedCounters = this.selectedCounters.filter((it) => it !== counter.id);
      this.updateCounterCache();
      this.countAbandonments();
    }
  }

  setCountersFromCache() {
    const cacheData = this.dataService.getConsoleIds() || {};
    if (Object.keys(cacheData).length === 0) return;

    const cachedSelection = cacheData[this.selectedQueue.id];
    this.selectedCounters = cachedSelection || [];
  }

  // Reset the counters for a branch queue
  updateCounterCache(): void {
    const cacheData = this.dataService.getConsoleIds() || {};
    cacheData[`${this.selectedQueue.id}`] = this.selectedCounters;
    this.dataService.setConsoleIds(cacheData);
  }

  // When a branch Queue is changed, then update the tokens and consoles
  toggleQueueSelection(queue: BranchQueue): void {
    if (queue.id == this.selectedQueue.id) return;

    this.selectedQueue = queue;
    this.resetFlags();
    this.loadTokens();
  }

  // Calculate wait times for the list of tokens and set a time ticker on them
  waitingTimesForToken(): void {
    const now = Date.now();
    const delay = (60 - ((now / 1000) % 60)) * 1000;
    const interval = 1000 * 60;

    this.waitTimesSub = timer(delay, interval).subscribe(() => {
      const { happy, neutral } = this.branchEndpoints.details || {};
      this.selectedQueueTokens.forEach((it) => it.updateWaitTime(happy, neutral));
    });
  }

  // Reset the data to load the new selected QUEUE.
  resetFlags(): void {
    this.consolesLoaded = false;
    this.selectedQueueTokens = [];
    this.queueCounters = [];
    this.selectedCounters = [];

    this.waitTimesSub.unsubscribe();
  }

  handleCountersModalChange(value) {
    this.showCountersModal = value;
  }

  handleRequestLockChange(value) {
    this.requestLocked = value;
  }

  handleApiError = (error) => {
    let message: string = GlobalVariables.DEFAULT_ERROR_MESSAGE;
    if (error?.[0]?.status == '404') message = GlobalVariables.ERROR_MESSAGE_404;
    else if (error?.[0]?.message) message = error[0].message;

    swal({
      text: message,
      type: 'warning',
      allowOutsideClick: false,
      showCancelButton: false,
      allowEscapeKey: false,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Refresh the page!',
    }).then(() => {
      this.requestLocked = false;
      if (error?.[0]?.status == '401') {
        this.dataService.clearData();
        this.router.navigate(['login']);
      } else {
        location.reload();
      }
    });
  };
}
