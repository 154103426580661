export class Mood {
  public happy;
  public neutral;

  constructor() {
    this.happy = 0;
    this.neutral = 0;
  }

  setMoodData(data): void {
    // We want to keep the moods on frontend in seconds
    this.happy = data.attributes.happy * 60 || 0;
    this.neutral = data.attributes.neutral * 60 || 0;
  }

  doMoodsExist() {
    return this.happy != 0 && this.neutral != 0;
  }
}

export enum MoodyEnum {
  happy = 'happy',
  neutral = 'neutral',
  sad = 'sad',
}
