<nav class="navbar navbar-main">
  <div class="nav-custom">
    <div class="queueoverview-navbar-header"></div>
    <span *ngIf="branchData">{{ branchData?.attributes?.name }}</span>
  </div>
</nav>

<div class="main-flex-container {{ queues?.length > 4 ? '--gt-4' : '' }}">
  <ng-container *ngFor="let queue of queues; let i = index">
    <div class="queues-detail-list">
      <div class="queue-title">
        <div class="queue-count">{{ queue?.tokens?.length }}</div>
        <span class="queue-name">{{ queue.name }} </span>
        <div *ngIf="mutedQueues.includes(queue.id)" class="volume-off-icon" (click)="muteQueue(queue.id)"></div>
        <div *ngIf="!mutedQueues.includes(queue.id)" class="volume-on-icon" (click)="muteQueue(queue.id)"></div>
      </div>

      <div class="queue-list-wrapper">
        <ng-container *ngFor="let token of queue.tokens">
          <div class="token" [@tokenAnimation]>
            <div class="token-origin --{{ token.originType || 'web_paper' }}"></div>
            <div class="queue-no">{{ token.tokenNumber }}</div>
            <div class="wait-time">{{ token.waitTime | readableTime }}</div>
            <div class="{{ token.mood }}-cls"></div>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>
