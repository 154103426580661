<h3>
  {{ branchService?.details?.name }}
</h3>

<h4 [ngClass]="{ '--empty': !longestWaitingToken?.tokenNumber }">Longest wait time</h4>
<button
  class="queue-list-item queue-list-longest-waiting"
  [ngClass]="{ '--empty': !longestWaitingToken?.tokenNumber }"
  (click)="handleLongestWaitingTokenClick(longestWaitingToken?.branchQueueId)"
>
  <ng-container *ngIf="longestWaitingToken?.tokenNumber">
    <div class="queue-list-longest-waiting-info">
      <span>{{ longestWaitingToken?.branchQueueName }}</span>
      <div>
        <span class="queue-list-longest-waiting-token">{{ longestWaitingToken?.tokenNumber }}</span>
        <i class="queue-list-longest-waiting-type icon-{{ longestWaitingToken?.originType }}"> </i>
      </div>
    </div>

    <div class="queue-list-longest-waiting-time">
      <span>{{ longestWaitingToken?.waitTime | readableTime }} </span>
      <i class="queue-list-longest-waiting-mood-icon {{ longestWaitingToken?.mood }}-cls"></i>
    </div>
  </ng-container>
</button>

<div class="queue-list-divider"></div>

<h4>Select queue</h4>
<button
  *ngFor="let queue of queues; let i = index"
  [ngClass]="{ active: queue.id == selectedQueue?.id }"
  (click)="handleQueueClick(queue)"
  class="queue-list-item"
>
  <div class="total-in-queue {{ queue.totalPeopleInQueue === 0 ? '--empty' : '' }}">
    <i class="user-icon"></i>
    <span>{{ queue.totalPeopleInQueue }}</span>
  </div>
  <span class="queue-label">{{ queue.name }}</span>
</button>

<span *ngIf="queues?.length === 0" class="loader-v-center"><i class="fa fa-spinner fa-pulse fa-fw fa-5x"></i></span>
